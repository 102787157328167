<template>
<div v-if="loaded">
  <div class="wp-block-columns is-style-8-8-8">
    <div v-for="(entry, idx) in currentEntries" :key="idx" class="wp-block-column">
      <div class="notdienst__card">
        <p><span class="fal fa-bell-exclamation notdienst__card__icon text-primary-dark"></span></p>

        <p class="h4 text-primary-dark"><span class="text-truncate d-block">{{ entry.apotheke }}</span>
          <small class="text-neutral">{{ entry.datum }}</small>
        </p>

        <p class="notdienst__card__info">
          <i class="fal fa-fw fa-map-marker-alt text-primary"></i> {{ entry.strasse}} <br/>
          <i class="fal fa-fw"></i> {{ entry.plz}} {{ entry.ort}}
        </p>

        <p class="notdienst__card__info">
          <i class="fal fa-fw fa-phone text-primary"></i> {{ entry.telefon}}<br/>
          <a :href="entry.googlemapslink" target="_blank" rel="noopener" class="link--arrow--light">
            <i class="fal fa-fw fa-route text-primary"></i> Route jetzt berechnen
          </a>
        </p>
      </div>
    </div>
  </div>

  <div class="notdienst__navigation">
    <div class="notdienst__info text-small">{{ infoText }}</div>

    <button class="btn btn-primary" @click.stop="prev" :disabled="!canPrev"><i class="far fa-angle-left ml-0"></i> Datum zurück</button>
    <button class="btn btn-primary" @click.stop="next" :disabled="!canNext">Datum vor <i class="far fa-angle-right mr-0"></i></button>
  </div>
</div>
</template>

<script>
export default {
  name: "Notdienst",

  components: {},

  props: ["infoText"],

  data() {
    return {
      entries:[],
      error:false,
      loaded:false,
      currentIndex: 0
    }
  },

  mounted() {
    this.load();
  },

  computed: {
    currentEntries() {
      if (this.entries[this.currentIndex]) {
        return this.entries[this.currentIndex];
      }

      return [];
    },

    canPrev() {
      if (this.currentIndex == 0) {
        return false;
      }

      return true;
    },

    canNext() {
      if (this.currentIndex == this.entries.length - 1) {
        return false;
      }

      return true;
    }
  },

  methods: {
    load() {
      window.depsloader(["axios"], (axios) => {
        axios.get("/wp-json/sw-drapo/v1/notdienst").then((res) => {
          this.entries = res.data;
          this.loaded = true;
        }).catch((e) => {
          this.error = true;
        })
      });
    },

    prev() {
      if (this.canPrev) {
        this.currentIndex--;
      }
    },

    next() {
      if (this.canNext) {
        this.currentIndex++;
      }
    }

  }
}
</script>
