import Vue from "vue";
import NotdienstApp from "./Notdienst.vue";

const element = document.querySelector("#vue-notdienst");
if (element) {
  new Vue({
    el: element,
    template: element,
    components: {
      NotdienstApp
    }
  });
}
