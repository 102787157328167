require('./overrides.scss')

import yeti from "../yeti";
const sassVariables = yeti.sassVariables();

const preset = {
  theme: {
    themes: {
      light: {
        primary: sassVariables["theme-primary"],
        secondary: sassVariables["theme-secondary"],
        accent: sassVariables["theme-accent"],
        error: sassVariables["theme-error"],
        info: sassVariables["theme-info"],
        success: sassVariables["theme-success"],
        warning: sassVariables["theme-warning"]
      },
    },
    options: {
      customProperties: true
    },
  },
}

export default preset;
