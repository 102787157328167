import deps from "./depsloader";

const yeti = {
  isTouchDevice: false,
  trackingEnabled: false,
  downlink: navigator.connection && navigator.connection.downlink,

  setupTracking: function() {
    const urlSearchParams = new URLSearchParams(window.location.search.substring(1));

    const campaign = urlSearchParams.get("campaign");
    const keyword = urlSearchParams.get("keyword");

    if (campaign || keyword) {
      deps.load("js-cookie").then((Cookies) => {
        Cookies.set("swtracking", JSON.stringify({
          campaign,
          keyword
        }));
      });
    }
  },

  isSlowInternet: function() {
    return !this.downlink || this.downlink < 3;
  },

  eventCache: [],
  trackEvent: function(category, action, label) {
    if (this.trackingEnabled && window.ga) {
      window.ga("send", "event", category, action, label);

      if (category == "conversion") {
        let event = {
            hitType: "event",
            eventCategory: "conversion_gads_query"
        };

        deps.load("js-cookie").then((Cookies) => {
          const swtracking = Cookies.get("swtracking");
          if (swtracking) {
            const data = JSON.parse(swtracking);

            if ("campaign" in data) {
              event["eventAction"] = data["campaign"];
            }

            if ("keyword" in data) {
              event["eventLabel"] = data["keyword"];
            }

            window.ga("send", event);
          }

          const googleAds = this.googleAds();
          if (googleAds && googleAds.conversionEvent && window.gtag) {
            window.gtag("event", "conversion", {"send_to": googleAds.conversionEvent});
          }
        });
      }
    } else {
      this.eventCache.push({
        category: category,
        action: action,
        label: label
      });
    }
  },

  sendEvents: function() {
    let events = this.eventCache;
    this.eventCache = [];
    events.forEach(event => {
      this.trackEvent(event.category, event.action, event.label);
    })
  },

  enableTracking: function() {
    this.trackingEnabled = true;
    this.sendEvents();
  },

  initTouchCategoryNavigation: function() {
    const items = document.querySelectorAll(".category-menu > li.has-children");
    const yeti = this;
    items.forEach(function(item) {
      item.querySelector("a").onclick = function(event) {
        if (!yeti.isTouchDevice) {
          return true;
        }

        if (!item.classList.contains("sw-hovered")) {
          event.preventDefault();
          item.classList.add("sw-hovered");
        } else {
          event.preventDefault();
          item.classList.remove("sw-hovered");
        }
      }

      item.onmouseenter = function(event) {
        if (!yeti.isTouchDevice) {
          event.preventDefault();
          item.classList.add("sw-hovered");
        }
      }

      item.onmouseleave = function(event) {
        window.setTimeout(function() {
          event.preventDefault();
          item.classList.remove("sw-hovered");
        }, 100)
      }
    });
  },

  readmore: function() {
    const readmores = document.querySelectorAll(".readmorejs.loading");
    if (readmores) {
      readmores.forEach((el) => {
        var clampElement = el.querySelector(".readmore__wrap");
        var toggleElement = el.querySelector(".readmore__state");
        var buttonElement = el.querySelector(".readmore__trigger");
        var elements = clampElement.children;
        el.classList.remove("loading");
        toggleElement.onchange = function() {
          let isClamped = !this.checked;
          clampElement.classList.remove("loading");
          clampElement.style.overflow = "";
          clampElement.style.height = "auto";
          clampElement.style.maxHeight = "none";
          for (let i = 0; i < elements.length; i++) {
            let child = elements[i];
            if (child.style.removeProperty) {
              child.style.removeProperty('webkitLineClamp');
              child.style.removeProperty('display');
            } else {
              child.style.removeAttribute('webkitLineClamp');
              child.style.removeAttribute('display');
            }
          }

          if (isClamped) {
            clampElement.style.overflow = "hidden";
            if (clampElement.style.removeProperty) {
              clampElement.style.removeProperty('height');
              clampElement.style.removeProperty('max-height');
            } else {
              clampElement.style.removeAttribute('height');
              clampElement.style.removeAttribute('max-height');
            }

            let clampHeight = clampElement.offsetHeight;
            let clampOffset = clampElement.offsetTop;
            let truncated = false;

            for (let i = 0; i < elements.length; i++) {
              let child = elements[i];
              let parentOffset = child.offsetTop - clampOffset;
              let childHeight = child.offsetHeight;
              if (parentOffset + childHeight > clampHeight) {
                if (!truncated) {
                  truncated = true;
                  //hier ist ende
                  let lineHeight = parseInt(getComputedStyle(child).lineHeight.replace("px", ""));
                  let spaceToShow = clampHeight - parentOffset;
                  let linesToShow  = Math.floor(spaceToShow / lineHeight);
                  let sty = child.style;
                  if (linesToShow) {
                    sty.overflow = 'hidden';
                    sty.textOverflow = 'ellipsis';
                    sty.webkitBoxOrient = 'vertical';
                    sty.display = '-webkit-box';
                    sty.webkitLineClamp = linesToShow;
                  } else {
                    child.style.display = "none";
                  }
                } else {
                  child.style.display = "none";
                }
              }
            }

            if (!truncated) {
              buttonElement.style.display = "none";
            } else {
              if (buttonElement.style.removeProperty) {
                buttonElement.style.removeProperty('display');
              } else {
                buttonElement.style.removeAttribute('display');
              }
            }
          }
        }

        toggleElement.onchange();

        window.addEventListener("resize", () => {
          toggleElement.onchange();
        }, false);
      });
    }
  }
}

const tmp = DOLLAR_YETI_GLOBAL_OVERWRITE; // eslint-disable-line

Object.keys(tmp).forEach(k => {
  yeti[k] = tmp[k];
});

window.addEventListener("touchstart", function onFirstTouch() {
    yeti.isTouchDevice = true;
    window.removeEventListener("touchstart", onFirstTouch, false);
}, false);

yeti.initTouchCategoryNavigation();
yeti.readmore();

let body = document.querySelector("body");

function handleNavScroll() {
  const scrollPosY = window.pageYOffset | document.body.scrollTop;

  if (scrollPosY > 70) {
    body.classList.add("has-scrolled");
  } else {
    body.classList.remove("has-scrolled");
  }
}

window.addEventListener("scroll", () => {
  handleNavScroll();
}, false);

handleNavScroll();

export default yeti;
