import Vue from "vue";
import Archive from "./Archive.vue";

const element = document.querySelector("#vue-testimonial-archive");
if (element) {
  new Vue({
    el: element,
    template: element,
    components: {
      Archive
    }
  });
}
