import Vue from "vue";
import Mainnavigation from "./Mainnavigation.vue";
import DropdownNavigation from "./DropdownNavigation.vue";
import DropdownTitle from "./DropdownTitle.vue";
import DropdownLinks from "./DropdownLinks.vue";
import DropdownClosebtn from "./DropdownClosebtn.vue";

const element = document.querySelector("#vue-mainnavigation");
if (element) {
  new Vue({
    el: element,
    template: element,
    components: {
      Mainnavigation,
      DropdownNavigation,
      DropdownTitle,
      DropdownLinks,
      DropdownClosebtn
    }
  });
} else {
  console.log("mainnavigation: #vue-mainnavigation not found");
}
