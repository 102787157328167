<template>
<div data-app>
  <div class="alert alert-success" v-if="sent">
    <strong>{{ $t('Ihre Anfrage wurde abgeschickt.') }}</strong>
  </div>

  <v-form @submit="submit" v-if="!sent">
    <div class="alert alert-danger" v-if="error">
      <strong>{{ $t('Es ist ein Fehler aufgetreten.') }}</strong>
    </div>

    <div class="alert alert-danger" v-if="userError">
      <strong>{{ $t('Bitte prüfen Sie Ihre Eingaben.') }}</strong>
    </div>

    <contact-form ref="contactForm"
      :privacy-url="privacyUrl"
      :required-fields="requiredFields"
      :enable-message="enableMessage"
      :enable-address="enableAddress"
      :enable-birthday="enableBirthday"></contact-form>

    <p class="button-wrapper"><button type="submit" class="btn btn-primary" @click="submit" v-html="submitButtonText"></button></p>
  </v-form>
</div>
</template>

<script>
import ContactForm from "./ContactForm";

export default {
  name: "ContactFormApp",

  props: {
    privacyUrl: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    requiredFields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    enableMessage: {
      type: Boolean,
      default: false
    },
    enableBirthday: {
      type: Boolean,
      default: false
    },
    enableAddress: {
      type: Boolean,
      default: false
    },
    submitButtonText: {
      type: String,
      default: "Anfrage senden <i class=\"far fa-angle-right\"></i>"
    }
  },

  components: {
    ContactForm
  },

  data() {
    return {
      userError: false,
      error: false,
      sent: false
    }
  },

  methods: {
    submit(e) {
      e.preventDefault();

      const contactForm = this.$refs.contactForm;

      if (!contactForm.validate()) {
        this.userError = true;
        return;
      }

      window.depsloader("axios", (axios) => {
        contactForm.contact["form"] = this.name;

        axios({
          url: "/wp-json/sw-core/v1/mailing",
          method: "post",
          data: contactForm.contact
        }).then(res => {
          this.sent = true;
        }).catch(err => {
          this.error = true;
        });
      });
    }
  }
};
</script>
