/*
Cookie Banner wird in die app.js geschrieben, d.h. keine großen Komponenten hier direkt includen.

Auf keinen Fall vuetify-Komponenten hier verwenden!
*/

import CookieNotice from "./CookieNotice.vue";

const element = document.querySelector("#vue-cookie-notice");
if (element) {
  window.depsloader(["vue", "vue-i18n"], function(Vue, i18n) {
    new Vue({
      el: element,
      template: element,
      components: {
        CookieNotice
      },
      i18n
    });
  });
} else {
  console.log("cookie-notice: #vue-cookie-notice not found");
}
